.root {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 100%;
  display: flex;
  padding: 3px 5px;
  flex-wrap: wrap;
  background-color: #ffffff;
}

.label {
  align-self: center;
  margin: 0 0 0 4px !important;
  padding: 0;
}

.input {
  padding: 0;
  margin: 0;
  flex-grow: 1;
}
