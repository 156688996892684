.root {
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
  display: inline-flex;
  border-radius: 3px;
  margin: 2px 5px;
  height: 30px;
}

.root:hover .closeButton {
  visibility: visible;
}

.root .label {
  background-color: #bae7ff;
  padding: 0 5px;
  margin-right: 5px;
  flex-shrink: 0;
  align-items: center;
  display: flex;
}

.closeButton {
  margin: 0 5px;
  cursor: pointer;
  visibility: hidden;
  color: #40a9ff;
}

.input {
  display: flex;
  align-items: center;
}
