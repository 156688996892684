.ribbon {
  height: 22px;
  padding-inline: 10px;
  align-items: center;
}

.root {
  /*margin: 0 10px;*/
}


.title {
  padding: 10px 0;
}

.list {
  height: 70vh;
  overflow-y: auto;
}

.dropPlace {
}
