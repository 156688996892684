.root{
    border: 1px transparent solid;
    border-radius: 3px;
    outline: none;
    resize: none;
    width: 100%;
    overflow: hidden;
}

.root:hover, .root:focus{
    border-color: lightgrey;
}
